<template>
  <div class="col-12 col-lg-8 c">
    <div class="card card-body">
        <div class="form-group">
            <h5 for="">رقم هوية الحاج</h5>
            <input type="text"
            class="form-control form-control-lg text-center" @change="search()" @keyup="search()" v-model="number" placeholder="اكتب رقم الهوية هنا...">
        </div>
        <div class="alert alert-warning text-center g" v-if="searching">
            جاري البحث...
        </div>
        <div class="alert alert-danger text-center g" v-if="error && !searching">
            {{ error }}
        </div>
        <template v-if="success">
            <hr>
            <div class="form-group">
              <h5 for="">اسم المبلغ</h5>
              <input type="text"
                class="form-control" v-model="create.name" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <h5 for="">رقم الحجز</h5>
              <input type="text"
                class="form-control" v-model="create.order_id" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <h5 for="">مدينة القدوم</h5>
              <input type="text"
                class="form-control" v-model="create.city" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <h5 for="">رقم الحافلة</h5>
              <input type="text"
                class="form-control" v-model="create.bus_number" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <h5 for="">رقم جواله</h5>
              <input type="text"
                class="form-control" v-model="create.phone" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <h5 for="">مشرف الحافلة</h5>
              <input type="text"
                class="form-control" v-model="create.bus_admin" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <h5 for="">تفاصيل البلاغ</h5>
              <textarea class="form-control" v-model="create.content" rows="3" placeholder="اكتب هنا..."></textarea>
            </div>
            <div class="form-group">
              <h6 for="">تصنيف البلاغ <a href="javascript:;" @click="manual_category = !manual_category"><i class="fa fa-edit"> كتابة يدوياً</i></a></h6>
              <select class="form-control" v-if="!manual_category" v-model="create.category">
                <option :value="undefined">-- اختر --</option>
                <option v-for="c in categories" :key="c">{{ c }}</option>
              </select>
              <input type="text"
                class="form-control" v-if="manual_category" v-model="create.category" placeholder="اكتب هنا...">
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="createNow()">
                    اضافة البلاغ
                </button>
            </div>
        </template>
    </div>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            number: "",
            error: null,
            success: null,
            current: {},
            categories: [],
            create: {},
            manual_category: false,
            searching: false,
            busses: []
        }
    },
    created(){
        if(!checkPer('add-ticket')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        $.post(api + '/admin/busses/list', {
            jwt: g.user.jwt
        }).then(function(r){
            g.busses = r.response;
        })
    },
    methods: {
        createNow(){
            var g = this;
            $.post(api + '/admin/users/create-ticket', {
                jwt: g.user.jwt,
                number: g.create.number,
                content: g.create.content,
                category: g.create.category,
                name: g.create.name,
                order_id: g.create.order_id,
                city: g.create.city,
                bus_number: g.create.bus_number,
                bus_admin: g.create.bus_admin,
                phone: g.create.phone,
                category: g.create.category,
            }).then(function(r){
                if(r.status == 100){
                    g.error = null;
                    g.success = null;
                    g.create = {}
                    g.number = ""
                    alert("تم الاضافة بنجاح", 100)
                }else{
                    alert(r.response, 200)
                }
            })
        },
        search(){
            var g = this;
            g.searching = true;
            $.post(api + '/admin/users/search-by-number', {
                jwt: g.user.jwt,
                number: g.number
            }).then(function(r){
                g.searching = false;
                if(r.status == 100){
                    g.error = null;
                    g.success = r.response.user.name;
                    g.current = r.response.user;
                    g.create.name = g.current.name;
                    g.create.order_id = g.current.order_id;
                    g.create.city = g.current.city;
                    g.create.phone = g.current.phone;
                    g.create.bus_number = g.current.bus_number;
                    g.create.bus_admin = g.current.bus_admin;
                    g.categories = r.response.categories.filter(function(x){
                        if(checkPer(x)){
                            return x;
                        }else{
                            return false;
                        }
                    });
                }else{
                    g.success = null;
                    g.error = r.response;
                }
            })
        }
    }

}
</script>

<style>

</style>